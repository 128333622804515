import React, { useContext, useState, useEffect } from "react"
import ActionLogs from "./ActionLogs"
import Select from "react-select"
import { debounce } from "lodash"

const MIN_CHARS = 3;

import {
  Row,
  Col,
  FormGroup,
  CardTitle,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import { Formik, Form } from "formik"
import { store as socketStore } from "components/Common/Socket/store"
import { useToasts } from "react-toast-notifications"
import { connect } from "react-redux"

import * as Yup from "yup"

import { getPlayers as getPlayersAction } from "../../../../../../UserAccounts/PlayerAccounts/actions"
import { getEventDetails as getEventDetailsAction } from "../../../../../actions"
import {
  addEntry,
  deleteEntry,
  moveEntry,
  setLuckyDraw,
  increaseWildeCards,
  decreaseWildeCards,
} from "../../../../../api"
import Slot from "../../components/Slot"

const competitionEntry = Yup.object().shape({
  // sele: Yup.object().required("Required"),
})

const Entries = ({
  competitionDetails,
  getPlayers,
  getEventDetails,
  players,
  eventDetails = {},
  match,
}) => {
  const { id, competitionId } = match.params
  const { addToast } = useToasts()

  //return color for free slots
  const colorHandler = (busySlots, competitionSlots) => {
    if (busySlots <= competitionSlots / 2) {
      return "text-success"
    } else if (busySlots >= competitionSlots) {
      return "text-danger"
    } else if (busySlots > competitionSlots / 2) {
      return "text-warning"
    }
  }

  let filteredCompetition = competitionDetails || {}

  //add entry modal
  const [modal, setModal] = useState(false)
  const [loadingResults, setLoadingResults] = useState(false)
  const [results, setResults] = useState([])
  const toggle = () => {
    setModal(!modal)
    setSelectedPlayer("")
    setSelectedPartner("")
    setLoadingResults(false)
    setWildCard(false)
  }

  //settings for get players
  const options = {
    page: 1,
    limit: 20,
    status: "activated",
  }

  const [selectedPlayer, setSelectedPlayer] = useState("")

  const globalState = useContext(socketStore)
  const {
    state: { socket: { socketClientId, socket } = {} } = {},
  } = globalState

  const registredSlots =
    filteredCompetition.mainList &&
    filteredCompetition.mainList?.filter(
      ({ slotType }) => slotType !== "WILD_CARD"
    ).length

  const wildcardSlots =
    filteredCompetition.mainList &&
    filteredCompetition.mainList?.filter(
      ({ slotType }) => slotType === "WILD_CARD"
    ).length

  const { gameType } = filteredCompetition

  useEffect(() => {
    const listenForResponse = async ({
      success,
      message,
      data: { id: newId } = {},
    }) => {
      if (!success) {
        return addToast(message, {
          appearance: "error",
          autoDismiss: true,
        })
      }

      await getEventDetails(id)

      return addToast(message, {
        appearance: "success",
        autoDismiss: true,
      })
    }
    if (socket) {
      socket.removeAllListeners("events-response")
      socket.on("events-response", listenForResponse)
    }
    return () => {
      socket && socket.removeAllListeners("events-response")
    }
  }, [socketClientId]) // eslint-disable-line

  useEffect(() => {
    setLoadingResults(true)

    if (players.results) {
      let playerList = []
      players.results
        .filter(({ status }) => status !== "deleted")
        .map(player =>
          playerList.push({
            value: `${player.firstName} ${player.lastName}`,
            label: `${player.firstName} ${player.lastName} id: ${player?.userId}
          Single: ${player?.gameLevel?.singles}
          Double: ${player?.gameLevel?.doubles} 
          ${player?.location?.country} ${player?.location?.city}
           account type: ${player?.membership?.plan}
          `,
            data: player,
          })
        )
      setResults(playerList)
    }
    setLoadingResults(false)
  }, [players]);

  const handleSearch = (string) => {
    setResults([])
    setLoadingResults(true);
    getPlayers({
      sportType: eventDetails?.sportType,
      string,
    }, options);
  }

  let entryPlayer = ""

  const [selectedPartner, setSelectedPartner] = useState("")

  const eventId = match.params.id

  let currentEvent = eventDetails

  //wild card
  const [wildCard, setWildCard] = useState(false)

  const getCurrentPlayerEntries = playerId => {
    let numberOfRegistrations = 0
    let allEntries = []
    currentEvent?.competitions?.map(competition => {
      allEntries = [
        ...allEntries,
        ...competition?.mainList,
        ...competition?.waitingList,
        ...competition?.preRegistrationList,
      ]
    })

    allEntries?.map(entry => {
      if (playerId === entry?.user?.userId || playerId === entry?.partnerId) {
        numberOfRegistrations = numberOfRegistrations + 1
      }
    })

    return numberOfRegistrations
  }

  const showPreRegistration = () => {
    if (
      currentEvent?.category === "gold" ||
      currentEvent?.category === "platinum" ||
      currentEvent?.category === "champions"
    ) {
      return (
        <div className="mb-2">
          <br />
          <CardTitle>Pre-registration</CardTitle>
          <Row>
            <Col xs={6}>
              <div className="d-flex flex-wrap row">
                {(filteredCompetition.preRegistrationList || []).length ? (
                  filteredCompetition.preRegistrationList.map(
                    (
                      {
                        user,
                        slotType,
                        createdBy,
                        partner,
                        _id,
                        amount,
                        paid,
                        status,
                        partnerId,
                        currency,
                      },
                      idx
                    ) => (
                      <Col className="col-sm-12">
                        <Slot
                          competitionDetails={competitionDetails}
                          currency={currency}
                          partnerId={partnerId}
                          status={status}
                          bottomSpace={true}
                          key={idx}
                          displayTeamAvg={true}
                          isWildecard
                          user={user}
                          amount={amount}
                          paid={paid}
                          gameType={gameType}
                          createdBy={createdBy}
                          partner={partner}
                          slotType={slotType}
                          position={idx + 1}
                          className={`slot ${
                            slotType === "WILD_CARD" && "slot__wildCard"
                          }`}
                          playerEntries={getCurrentPlayerEntries(user?.userId)}
                          partnerEntries={getCurrentPlayerEntries(partnerId)}
                          Actions={() => (
                            <Actions noMove _id={_id} list="preEntries" />
                          )}
                        />
                      </Col>
                    )
                  )
                ) : (
                  <div className="col-12">No items yet</div>
                )}
              </div>
            </Col>
          </Row>
          <Button
            className="mb-3 ml-0 mt-2"
            color="primary"
            type="button"
            onClick={() => setModal("preRegistrationList")}
            disabled={
              currentEvent?.currentPhase?.status === "preRegistrationsOpen"
                ? false
                : true
            }
          >
            Add entry
          </Button>

          <br />
        </div>
      )
    }
  }

  const Actions = ({ noMove, list, _id }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    return (
      <div>
        <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)}>
          <ModalHeader toggle={() => setDeleteModal(false)}>
            Confirm
          </ModalHeader>
          <ModalBody>Are you sure you want to delete this entry?</ModalBody>
          <ModalFooter>
            <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
            <Button
              color="primary"
              onClick={async () => {
                await deleteEntry({
                  competitionId,
                  eventId,
                  socketClientId,
                  entryId: _id,
                })
                await getEventDetails(eventId)
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <a
          href="javascript:;"
          onClick={async () => {
            setDeleteModal(true)
          }}
        >
          <i title="Remove entry" className="bx bx-trash-alt"></i>
        </a>{" "}
        {!noMove && (
          <a
            href="javascript:;"
            onClick={async () => {
              await moveEntry({
                competitionId,
                eventId,
                socketClientId,
                entryId: _id,
                list,
              })
            }}
          >
            <i
              title={
                list !== "mainList"
                  ? "Move to waiting list"
                  : "Move to main list"
              }
              className={`bx ${
                list !== "mainList" ? "bx-right-arrow-alt" : "bx-left-arrow-alt"
              }`}
            ></i>
          </a>
        )}
      </div>
    )
  }

  const [luckyDrawModal, setLuckyDrawModal] = useState(false)

  const toggleLuckyDrawModal = () => setLuckyDrawModal(!luckyDrawModal)

  const EmptyWildCardsNumber = (mainList, wildCardsNumber) => {
    let mainListWC = 0

    mainList?.map(slot => {
      if (slot?.slotType === "WILD_CARD") {
        mainListWC = mainListWC + 1
      }
    })

    return wildCardsNumber - mainListWC
  }

  return (
    <>
      <div>
        {/* pre-registration */}
        {showPreRegistration()}
        <br />
        {/* main list */}
        <Row>
          <div className="col-6">
            <CardTitle className="d-flex mb-0">
              <span className="mr-2">Total Entries</span>{" "}
              <p
                className={`${colorHandler(
                  filteredCompetition?.mainList?.length,
                  filteredCompetition.mlMaxNumber
                )}`}
              >
                {"(" +
                  filteredCompetition?.mainList?.length +
                  "/" +
                  filteredCompetition?.mlMaxNumber +
                  ")"}
              </p>
            </CardTitle>
            <CardTitle className="d-flex min-height-40 justify-content-between align-items-center mb-3">
              <div className="d-flex">
                <span className="mr-2">Main List Management </span>
                <p
                  className={`${colorHandler(
                    registredSlots,
                    filteredCompetition.mlMaxNumber
                  )} mb-0`}
                >
                  ({registredSlots}/
                  {filteredCompetition.mlMaxNumber -
                    filteredCompetition.wildCards}
                  )
                </p>

                <div className="d-flex align-items-center ml-4">
                  <span className="mr-2 fs-14 color-violetred">
                    Wild cards{" "}
                  </span>
                  <p className="color-orange mb-0 mr-1">
                    ({wildcardSlots}/{filteredCompetition.wildCards})
                  </p>
                  <i
                    title="Add wildcard"
                    className="bx ml-1 mr-1 bxs-plus-circle color-purple wildcard__action"
                    onClick={async () => {
                      try {
                        await increaseWildeCards({
                          id,
                          socketClientId,
                          competitionId,
                        })
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  ></i>
                  <i
                    title="Remove wildcard"
                    className="bx ml-1 mr-1 bxs-minus-circle color-gold wildcard__action"
                    onClick={async () => {
                      try {
                        await decreaseWildeCards({
                          id,
                          socketClientId,
                          competitionId,
                        })
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  ></i>
                </div>
              </div>
              <div>
                <Button
                  className="mb-0 mr-2"
                  color="secondary"
                  type="button"
                  disabled={
                    filteredCompetition.mainList &&
                    filteredCompetition.mainList.length ===
                      filteredCompetition.mlMaxNumber
                  }
                  onClick={() => {
                    toggle()
                    setWildCard(true)
                  }}
                >
                  Add wild card
                </Button>

                <Button
                  className="mb-0 ml-0"
                  color="primary"
                  type="button"
                  onClick={toggle}
                >
                  Add entry
                </Button>
              </div>
            </CardTitle>

            {/* MAIN LIST ENTRIES */}
            <div className="d-flex flex-wrap row">
              {(filteredCompetition.mainList || []).length ? (
                filteredCompetition.mainList.map(
                  (
                    {
                      user,
                      slotType,
                      createdBy,
                      createdById,
                      partner,
                      _id,
                      amount,
                      paid,
                      status,
                      partnerId,
                      currency,
                    },
                    idx
                  ) => (
                    <Col className="col-sm-12">
                      <Slot
                        competitionDetails={competitionDetails}
                        currency={currency}
                        partnerId={partnerId}
                        status={status}
                        bottomSpace={true}
                        key={idx}
                        displayTeamAvg={true}
                        isWildecard
                        user={user}
                        amount={amount}
                        paid={paid}
                        gameType={gameType}
                        createdBy={createdBy}
                        createdById={createdById}
                        partner={partner}
                        slotType={slotType}
                        position={idx + 1}
                        playerEntries={getCurrentPlayerEntries(user?.userId)}
                        partnerEntries={getCurrentPlayerEntries(partnerId)}
                        className={`slot ${
                          slotType === "WILD_CARD" && "slot__wildCard"
                        }`}
                        Actions={() => <Actions _id={_id} list="waitingList" />}
                      />
                    </Col>
                  )
                )
              ) : (
                <div className="col-12">No items yet</div>
              )}
              {/* map empty wild cards */}
              {Array.from({
                length: EmptyWildCardsNumber(
                  filteredCompetition?.mainList,
                  filteredCompetition?.wildCards
                ),
              })?.map((wildCard, index) => (
                <Col key={index} className="col-sm-12">
                  <Slot emptyWC={true} className={`slot ${"slot__wildCard"}`} />
                </Col>
              ))}
            </div>
          </div>
          {/*Waiting list*/}
          <div className="col-6 mb-4">
            <CardTitle className="mb-3 min-height-40 align-items-center d-flex">
              Waiting List Management
            </CardTitle>
            <div className="d-flex flex-wrap row">
              {(filteredCompetition.waitingList || []).length ? (
                filteredCompetition.waitingList.map(
                  (
                    {
                      user,
                      partner,
                      slotType,
                      _id,
                      amount,
                      paid,
                      createdBy,
                      createdById,
                      status,
                      partnerId,
                      currency,
                    },
                    idx
                  ) => (
                    <Col className="col-sm-12">
                      <div className="d-flex align-items-center">
                        <p className="mr-2">{idx + 1}</p>
                        <Slot
                          competitionDetails={competitionDetails}
                          partnerId={partnerId}
                          key={idx}
                          currency={currency}
                          status={status}
                          bottomSpace={true}
                          displayTeamAvg={true}
                          user={user}
                          amount={amount}
                          paid={paid}
                          gameType={gameType}
                          createdBy={createdBy}
                          createdById={createdById}
                          partner={partner}
                          slotType={slotType}
                          playerEntries={getCurrentPlayerEntries(user?.userId)}
                          partnerEntries={getCurrentPlayerEntries(partnerId)}
                          className={`slot slot__waiting ${
                            slotType === "WILD_CARD" && "slot__wildCard"
                          }`}
                          Actions={() => <Actions _id={_id} list="mainList" />}
                        />
                      </div>
                    </Col>
                  )
                )
              ) : (
                <div className="col-12">No items yet</div>
              )}
            </div>
          </div>
        </Row>
      </div>
      <div>
        <Formik
          key={entryPlayer}
          initialValues={{
            entryPlayer,
          }}
          validationSchema={competitionEntry}
          onSubmit={async ({ entryPlayer }) => {
            try {
              if (typeof selectedPlayer !== "object" || !selectedPlayer) {
              } else {
                const duoLevel = [
                  selectedPlayer?.data?.gameLevel?.doubles,
                  selectedPartner?.data?.gameLevel?.doubles,
                ]?.sort((a, b) => b - a)
                if (duoLevel?.length === 2 && duoLevel[0] - duoLevel[1] > 2) {
                  return addToast(
                    "The registration cannot be processed. Levels in your team do not correspond to the level restriction applied to this competition.",
                    {
                      appearance: "error",
                      autoDismiss: true,
                    }
                  )
                } else {
                  await addEntry({
                    preRegistrationList: modal === "preRegistrationList",
                    competitionId,
                    id,
                    socketClientId,
                    player: selectedPlayer,
                    partner: selectedPartner,
                    wild: wildCard,
                  })
                }
              }
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {({
            errors,
            touched,
            initialValues,
            handleBlur,
            handleChange,
            setFieldValue,
            values,
          }) => {
            return (
              <>
                {/* add entry modal */}
                <Modal
                  isOpen={modal}
                  toggle={() => {
                    toggle()
                  }}
                >
                  <Form>
                    <ModalHeader
                      toggle={() => {
                        toggle()
                      }}
                    >
                      Add entry
                    </ModalHeader>
                    <ModalBody>
                      {/* player selector */}
                      <FormGroup>
                        <Label>Player</Label>
                        <Select
                          placeholder="Search for player..."
                          options={results}
                          isOptionSelected={(option, selectValue) =>
                            selectValue.some(i => i === option)
                          }
                          onInputChange={debounce((e) => {
                            if (e?.length >= MIN_CHARS) {
                              handleSearch(e)
                            }
                          }, 100)}
                          onChange={e => {
                            setSelectedPlayer(e)
                          }}
                          noOptionsMessage={({ inputValue }) => (<>
                            {inputValue.length >= MIN_CHARS && !loadingResults && (<>No results found.</>)}
                            {inputValue.length < MIN_CHARS && (<>Write at least 3 characters...</>)}
                            {loadingResults && (<>Loading...</>)}
                          </>)}
                        />
                      </FormGroup>
                      {/* duo selector */}
                      {gameType === "doubles" ? (
                        <FormGroup>
                          <Label>Partner</Label>
                          <Select
                            placeholder="Search for partner..."
                            options={results}
                            isOptionSelected={(option, selectValue) =>
                              selectValue.some(i => i === option)
                            }
                            onInputChange={debounce((e) => {
                              if (e?.length >= MIN_CHARS) {
                                handleSearch(e)
                              }
                            }, 100)}
                            onChange={e => {
                              setSelectedPartner(e)
                            }}
                            noOptionsMessage={({ inputValue }) => (<>
                              {inputValue.length >= MIN_CHARS && !loadingResults && (<>No results found.</>)}
                              {inputValue.length < MIN_CHARS && (<>Write at least 3 characters...</>)}
                              {loadingResults && (<>Loading...</>)}
                            </>)}
                          />
                        </FormGroup>
                      ) : (
                        ""
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        disabled={selectedPlayer?.data?.userId === selectedPartner?.data?.userId}
                        type="submit"
                        color="primary"
                      >
                        Add
                      </Button>{" "}
                      <Button color="secondary" onClick={toggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
              </>
            )
          }}
        </Formik>
      </div>
      <div className="d-flex flex-column">
        <div>
          <LuckyDrawModal
            competitionId={competitionId}
            eventId={eventId}
            socketClientId={socketClientId}
            open={luckyDrawModal}
            action={toggleLuckyDrawModal}
          />

          <Button
            color="success"
            className="pl-1 pr-1"
            onClick={
              filteredCompetition?.luckyDraw?.length < 1
                ? async () => {
                    await setLuckyDraw({
                      competitionId,
                      eventId,
                      socketClientId,
                    })
                  }
                : () => {
                    toggleLuckyDrawModal()
                  }
            }
            className="mb-4 mt-3"
          >
            Set lucky draw
          </Button>
        </div>
        <ActionLogs
          competitionId={competitionId}
          filteredCompetition={filteredCompetition}
          ml={filteredCompetition.mainList}
          wl={filteredCompetition.waitingList}
        />
      </div>
    </>
  )
}

const LuckyDrawModal = ({
  open,
  action,
  competitionId,
  eventId,
  socketClientId,
}) => {
  return (
    <Modal isOpen={open} toggle={action}>
      <ModalHeader action={action}>Confirmation Modal</ModalHeader>
      <ModalBody>Are you sure you want to reset the lucky draw</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={async () => {
            await setLuckyDraw({
              competitionId,
              eventId,
              socketClientId,
            })

            action()
          }}
        >
          Yes
        </Button>{" "}
        <Button color="secondary" onClick={action}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = ({
  players: { players } = {},
  events: { events = [], eventDetails } = {},
  session: session = {},
}) => ({
  players,
  events,
  session,
})

export default connect(mapStateToProps, {
  getPlayers: getPlayersAction,
  getEventDetails: getEventDetailsAction,
})(Entries)
