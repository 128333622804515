import moment from "moment"

const activityColumns = () => [
  {
    dataField: "_id",
    text: "#",
    formatter: (cellContent, order) => <strong>{order._id}</strong>,
  },
  {
    dataField: "createdAt",
    text: "Add Date",
    formatter: cellContent => (
      <>{moment(cellContent?.createdAt).format("YYYY-MM-DD HH:mm")}</>
    ),
  },
  {
    dataField: "type",
    text: "Type",
  },
  {
    dataField: "gameLevel.singles",
    text: "Level",
    formatter: (cellContent, order) => (
      <>{order?.body?.extra?.level ? order?.body?.extra?.level : "-"}</>
    ),
  },
  {
    dataField: "draw",
    text: "Draw",
    formatter: (cellContent, order) => (
      <>{order?.body?.extra?.draw ? order?.body?.extra?.draw : "-"}</>
    ),
  },
  {
    dataField: "points",
    text: "Points",
    formatter: (cellContent, order) => (
      <>{order?.body?.extra?.points ? order?.body?.extra?.points : "-"}</>
    ),
  },
  {
    dataField: "tournament",
    text: "Tournament",
    formatter: (cellContent, order) => (
      <>{order?.body?.extra?.eventName ? order?.body?.extra?.eventName : "-"}</>
    ),
  },
]

export default activityColumns
