import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment"

import {get, post} from "helpers/api_helper"

const listColumns = (userId, impersonateKey) => [
  {
    dataField: "userId",
    text: "ID",
    sort: true,
  },
  {
    dataField: "firstName",
    text: "Name",
    formatter: (cellContent, user) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to={`/accounts/players/${user.id}`} className="text-dark">
            {user.firstName} {user.lastName}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "phone",
    text: "Phone",
  },
  {
    dataField: "location",
    text: "Location",
    formatter: (cellContent, user) => {
      let loc
      if (user?.location?.name) {
        loc = user?.location?.name?.split(",")
      }
      return (
        <div className="d-flex flex-column">
          {loc?.length > 0
            ? loc?.map((name, idx) => (
                <span key={idx} className="text-capitalize">
                  {name}
                </span>
              ))
            : "n/a"}
        </div>
      )
    },
  },
  {
    dataField: "levels",
    text: "Levels",
    formatter: (cellContent, user) => (
      <>
        <span className="badge badge-soft-primary font-size-12 m-1">
          <i className="bx bx-user" /> - {user.gameLevel?.singles ?? "n/a"}
        </span>
        <br />
        <span className="badge badge-soft-primary font-size-12 m-1">
          <i className="bx bx-user" /> <i className="bx bx-user" /> -{" "}
          {user.gameLevel?.doubles ?? "n/a"}
        </span>
      </>
    ),
  },
  {
    dataField: "age",
    text: "Age",
    formatter: (cellContent, user) => (
      <>
        {user.birthDate
          ? moment().year() - moment(user.birthDate).year()
          : "n/a"}{" "}
      </>
    ),
  },
  {
    dataField: "gender",
    text: "Gender",
    formatter: (cellContent, user) => (
      <>
        <i className={`bx bx-${user.gender} fa-2x`} />
      </>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, user) => (
      <>
        <span
          className={`badge badge-${
            user.status === "activated"
              ? "success"
              : user.status === "pending"
              ? "info"
              : user.status === "blocked"
              ? "warning"
              : "danger"
          } font-size-12`}
        >
          {user.status}
        </span>
      </>
    ),
  },
  {
    dataField: "createdAt",
    text: "Date Created",
    sort: true,
    formatter: (cellContent, user) => {
      return (
        <div className="d-flex flex-column">
          <p className="mb-0">{moment(user.createdAt).format("YYYY-MM-DD")}</p>
          <p className="mb-0">{moment(user.createdAt).format("HH:mm:ss")}</p>
        </div>
      )
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    formatter: (cellContent, data) => {
      return (
        <>
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={async () => {
                  try {
                    const { data: { impersonateKey } } = await get("/admin/accounts/auth");

                    await post(`/accounts/auth?impersonate=true&userId=${userId}&hash=${impersonateKey}`, {
                      email: data?.email,
                      password: "password",
                    })
                    window.open(
                      `${process.env.REACT_APP_WEB_URL}/accounts/${data?.id}`
                    )
                  } catch {
                    console.log("error")
                  }
                }}
                className="text-center"
                target="_blank"
              >
                Impersonate
              </DropdownItem>
              <DropdownItem className="text-center p-0">
                <Link
                  to={`/accounts/players/${data.id}/edit`}
                  className="d-block dropdown-item"
                >
                  Edit user
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )
    },
  },
]

export default listColumns
